<script lang="ts" setup>
import type { PropType } from 'vue'

import OverlayBase from '@/components/overlays/OverlayBase.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import TwoColsBlock from '@/components/layout/TwoColsBlock.vue'
import SkrHeading from '@/components/SkrHeading.vue'
import SkrButtonGroup from '@/components/button/SkrButtonGroup.vue'
import SkrButton from '@/components/button/SkrButton.vue'

const props = defineProps({
  message: String,
  status: String,
  processedRequests: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
})

const emit = defineEmits<{
  (e: 'action', type: OverlayAction, data?: unknown): void
  (e: 'close'): void
}>()

const { t } = useI18n()

const { areExitUrlsApplicable, leaveToErrorUrl } = useExitUrlStore()

const isCustomExitUrl = computed(() => areExitUrlsApplicable(props.processedRequests))

const signingStatus = computed(() => props.status)
const signingMessage = computed(() => props.message)

const isSingleDocument = computed(() => props.processedRequests.length === 1)

const info = computed<{ title: string; subtitle: string; actionText: string; actionLink?: string }>(() => {
  switch (signingStatus.value) {
    case 'cancelled':
      return {
        title: t('sign.error.cancelled.title'),
        subtitle: t('sign.error.cancelled.subtitle'),
        actionText: isSingleDocument.value ? t('sign.error.one.back') : t('sign.error.many.back'),
      }
    case 'timeout':
      return {
        title: t('sign.error.timeout.title'),
        subtitle: t('sign.error.timeout.subtitle'),
        actionText: t('sign.error.restart'),
      }
  }

  if (signingMessage.value?.includes('SerialNumberMismatch')) {
    return {
      title: t('sign.error.serialnomismatch.title'),
      subtitle: t('sign.error.serialnomismatch.subtitle'),
      actionText: t('sign.error.serialnomismatch.cta'),
    }
  } else if (signingMessage.value?.includes('status:InvalidPassword')) {
    return {
      title: t('pwd_otp.pwd.error.title'),
      subtitle: t('pwd_otp.pwd.error.text'),
      actionText: t('pwd_otp.pwd.error.cta'),
    }
  } else if (signingMessage.value?.includes('status:InvalidOtp')) {
    return {
      title: t('pwd_otp.otp.error.title'),
      subtitle: t('pwd_otp.otp.error.text'),
      actionText: t('pwd_otp.otp.error.cta'),
    }
  } else if (signingMessage.value?.includes('no_free_signatures_remaining')) {
    return {
      title: t('sign.error.no_free_signatures.title'),
      subtitle: t('sign.error.no_free_signatures.subtitle'),
      actionText: t('sign.error.no_free_signatures.cta'),
    }
  } else {
    return {
      title: t('sign.error.title'),
      subtitle: t('sign.error.general_error'),
      actionText: t('sign.error.restart'),
    }
  }
})

const image = computed(() => {
  switch (signingStatus.value) {
    case 'cancelled':
      return { source: 'sign-cancel', width: 565, height: 414 }
    case 'timeout':
      return { source: 'verification-code-expired', width: 560, height: 510 }
  }

  if (signingMessage.value?.includes('SerialNumberMismatch')) {
    return { source: 'identity-error', width: 416, height: 410 }
  } else if (signingMessage.value?.includes('status:InvalidPassword')) {
    return { source: 'incorrect-password', width: 516, height: 441 }
  } else if (signingMessage.value?.includes('status:InvalidOtp')) {
    return { source: 'verification-code-wrong', width: 688, height: 426 }
  } else if (signingMessage.value?.includes('no_free_signatures_remaining')) {
    return { source: 'error-no-free-sigs', width: 560, height: 406 }
  } else {
    return { source: 'sign-error', width: 554, height: 352 }
  }
})

const onAction = () => {
  emit('close')
}
</script>

<template>
  <overlay-base :transition="false">
    <two-cols-block split="5/7" class="fill-height">
      <template #left>
        <div class="mb-8">
          <skr-heading level="1" data-cy="title" semantic>{{ info.title }}</skr-heading>
          <skr-heading level="2">{{ info.subtitle }}</skr-heading>
        </div>
        <skr-button-group size="lg" stacked>
          <skr-button v-if="info.actionLink" :href="info.actionLink">
            {{ info.actionText }}
          </skr-button>
          <skr-button v-else @click="onAction">
            {{ info.actionText }}
          </skr-button>
          <skr-button v-if="isCustomExitUrl" type="text" @click="leaveToErrorUrl">
            {{ t('global.cancel') }}
          </skr-button>
        </skr-button-group>
      </template>
      <template #right>
        <responsive-image v-bind="image" />
      </template>
    </two-cols-block>
  </overlay-base>
</template>
